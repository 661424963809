@import url("https://fonts.googleapis.com/css2?family=Timmana");
@import url("https://fonts.googleapis.com/css2?family=Sanchez");


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


 /* @media (prefers-reduced-motion: no-preference) {
   .logo {
     animation: fadeIn linear 3s;
      
  }
  .info {
    animation: slideIn linear 3s backwards;
    transform:translateX(-200%);
  }
 } */

.PopUp {
  font-family: "Timmana", sans-serif;
  /* -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; */
  font-size: 80px;
  color: #FFFFFF;
}

.Feed {
  font-family: "Sanchez", sans-serif;
  /* -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; */
  font-size: 64px;
  color: #D1D1D1;
}

.email {
  color: #D1D1D1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes slideIn {
  to {
    transform:translateX(0);
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}